<template>
  <v-app>
    <div v-if="desks">
      <v-app-bar app color="#E61E28" dense dark>
        <v-toolbar-title>{{ $store.state.site }} desks</v-toolbar-title>
        <small class="ml-3"> {{ $store.state.user.username }}</small>
        <v-spacer></v-spacer>
        <v-btn
          dark
          icon
          @click="
            serial_no = '';
            desk_id = '';
            dialog = true;
          "
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-app-bar>
      <v-main>
        <v-container fluid>
          <v-row>
            <v-col cols="12" md="2">
              <v-text-field
                v-model="search"
                label="Search"
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
          <v-data-table
            :headers="headers"
            :items="desks"
            :items-per-page="25"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            multi-sort
          >
            <template v-slot:[`item.desk_id`]="{ item }">
              <a
                @click="
                  serial_no = item.serial_no;
                  desk_id = item.desk_id;
                  group = item.group in groups ? item.group : 'production';
                  dialog = true;
                "
                >{{ item.desk_id
                }}<span v-if="item.new_id"> ({{ item.new_id }})</span></a
              >
            </template>
            <template v-slot:[`item.state`]="{ item }">
              <v-chip :color="getColor(item.state)" dark v-if="timedOut(item)">
                {{ item.state }}
              </v-chip>
              <v-chip color="grey" dark v-else> timed out </v-chip>
            </template>
            <template v-slot:[`item.last_heartbeat`]="{ item }">
              <v-icon left :color="timedOut(item) ? 'green' : 'grey'"
                >mdi-access-point</v-icon
              >
              {{ $date(item.last_heartbeat).from(current) }}
            </template></v-data-table
          >
        </v-container>
      </v-main>

      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar color="#E61E28" dense dark>
            <v-toolbar-title>Provision</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="saveDesk()"
          >
            <v-card-text class="py-3">
              <div v-if="scanning" style="max-width: 600px; text-align: centre">
                <qrcode-stream
                  @decode="onDecode"
                  :camera="camera"
                  @init="onInit"
                >
                  <v-btn fab @click="switchCamera">
                    <v-icon>mdi-camera-switch</v-icon>
                  </v-btn>
                  <v-btn fab @click="scanning = false" class="float-right">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </qrcode-stream>
              </div>
              <v-text-field
                v-model="serial_no"
                label="Serial/MAC Address"
                :error-messages="scanError"
                :rules="serialnoFormat"
                required
                autocomplete="false"
              >
                <template v-slot:append>
                  <v-btn icon @click="scanning = true">
                    <v-icon>mdi-qrcode-scan</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
              <v-text-field
                v-model="desk_id"
                label="Desk ID"
                hint="This must match the Serraview space name exactly (case-sensitive)"
                ref="desk_id"
                :rules="deskidFormat"
                required
                autocomplete="false"
              ></v-text-field>
              <v-select
                v-model="group"
                label="Group"
                ref="group"
                :items="groups"
                required
              ></v-select>
              <v-alert v-if="formError" type="error">
                {{ formError }}
              </v-alert>
            </v-card-text>
            <v-card-actions>
              <v-btn text color="#E61E28" class="mt-3" @click="clearDesk()">
                Clear Record
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                dark
                color="#E61E28"
                type="submit"
                class="mt-3"
                :saving="saving"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </div>
  </v-app>
</template>

<script>
import { Api } from "@/services/api";
import { QrcodeStream } from "vue-qrcode-reader";
import { loginRequest, apiRequest } from "@/authConfig";
import { getAuthToken } from "@/plugins/msal";

export default {
  components: {
    QrcodeStream,
  },
  data: () => ({
    current: null,
    headers: [
      { text: "ID", value: "desk_id" },
      { text: "MAC", value: "serial_no" },
      { text: "IP", value: "ip" },
      { text: "State", value: "state" },
      { text: "Group", value: "group" },
      { text: "Version", value: "version" },
      { text: "Last Heartbeat", value: "last_heartbeat" },
    ],
    states: {
      unprovisioned: "pink",
      setup: "black",
      sleeping: "grey",
      booked: "orange",
      available: "green",
      occupied: "blue",
      unavailable: "red",
    },
    groups: [
      { text: "Production", value: "production" },
      { text: "Staging", value: "staging" },
    ],
    search: "",
    sortBy: ["last_heartbeat",],
    sortDesc: [false,],
    dialog: false,
    serial_no: "",
    desk_id: "",
    group: "production",
    camera: "front",
    scanning: false,
    scanError: "",
    valid: true,
    serialnoFormat: [
      (v) => !!v || "Serial/MAC is required",
      (v) =>
        /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/.test(v) ||
        "MAC must be valid format",
    ],
    deskidFormat: [
      (v) => !!v || "Desk ID is required",
      (v) => !v.includes("iotdesk-") || "Desk ID must not contain iotdesk-",
      (v) =>
        //eslint-disable-next-line
        /^[A-Z0-9a-z-_\.]+$/.test(v) ||
        "Desk ID must not contain spaces or special characters",
    ],
    saving: false,
    formError: "",
  }),
  computed: {
    desks() {
      return Object.values(this.$store.state.desks).filter((x) => {
        if (!this.search) return true;
        if (
          x.desk_id.includes(this.search) ||
          x.ip.includes(this.search) ||
          x.serial_no.includes(this.search) ||
          x.version == this.search ||
          x.state == this.search ||
          x.group == this.search
        )
          return true;
        return false;
      });
    },
  },
  methods: {
    getColor(state) {
      return this.states[state];
    },
    startInterval: function () {
      setInterval(() => {
        this.current = this.$date();
      }, 1000);
    },
    timedOut(item) {
      if (this.current) {
        if (
          this.current.diff(this.$date(item.last_heartbeat), "second") < 600
        ) {
          return true;
        }
      }
    },
    onDecode(decodedString) {
      const setting = this.camera;
      this.camera = "off";
      this.scanError = "";

      try {
        const data = JSON.parse(decodedString);
        this.serial_no = data.serial_no;
      } catch (error) {
        this.scanError = "correct qrcode format not found, try again";
      }

      this.scanning = false;
      this.camera = setting;
      this.$refs.desk_id.focus();
    },
    scanFailure(error) {
      console.warn(`Code scan error = ${error}`);
    },
    switchCamera() {
      switch (this.camera) {
        case "front":
          this.camera = "rear";
          break;
        case "rear":
          this.camera = "front";
          break;
      }
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        console.log("camera error: ", error);
      }
    },
    async saveDesk() {
      this.saving = true;
      this.formError = "";
      if (this.$refs.form.validate()) {
        const payload = {
          serial_no: this.serial_no,
          desk_id: this.desk_id,
          group: this.group,
        };
        await Api()
          .post("/api/provision", payload)
          .then(() => {
            this.dialog = false;
          })
          .catch((err) => {
            this.formError = err.response.data;
          });
        this.saving = false;
      }
    },
    async clearDesk() {
      this.saving = true;
      const payload = {
        desk_id: this.desk_id,
      };
      await Api()
        .post("/api/clear", payload)
        .then(() => {
          this.$store.dispatch("getDesks");
          this.dialog = false;
        })
        .catch((err) => {
          this.formError = err.response.data;
        });
      this.saving = false;
    },
    async handleResponse(resp) {
      if (resp !== null) {
        this.$store.commit("updateUser", resp.account);
      } else {
        const currentAccounts = this.$msal.getAllAccounts();
        if (!currentAccounts || currentAccounts.length < 1) {
          this.$msal.loginRedirect(loginRequest);
        } else if (currentAccounts.length === 1) {
          this.$store.commit("setUser", currentAccounts[0]);
        }
      }
      await getAuthToken(loginRequest);
      this.$store.commit("setapiToken", await getAuthToken(apiRequest));
      this.$store.dispatch("getDesks");

      this.$socket.client.io.opts.extraHeaders.Authorization =
        "Bearer " + this.$store.state.apiToken;
      this.$socket.client.open();

      this.startInterval();
    },
  },
  sockets: {
    async deskupdate(desk) {
      this.$store.commit("deskUpdate", desk);
    },
  },
  mounted() {
    this.$msal.handleRedirectPromise().then(this.handleResponse);
  },
};
</script>