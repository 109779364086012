import Vue from "vue";
import Vuex from "vuex";
import { Api } from "@/services/api";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: "",
    apiToken: "",
    isConnected: false,
    site: "",
    desks: {},
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setapiToken(state, apiToken) {
      state.apiToken = apiToken;
    },
    setSite(state, site) {
      state.site = site;
    },
    setDesks(state, desks) {
      state.desks = desks.reduce(
        (all, desk) => ((all[desk.serial_no] = desk), all),
        {}
      );
    },
    deskUpdate(state, desk) {
      Vue.set(state.desks, desk.serial_no, desk);
    },
  },
  actions: {
    getDesks({ commit }) {
      Api()
        .get("/api/desks")
        .then((response) => {
          commit("setSite", response.data.org + "/" + response.data.site);
          commit("setDesks", response.data.desks);
        });
    },
  },
  modules: {},
});
