import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import vuetify from "./plugins/vuetify";
import dayjs from "./plugins/dayjs";

import { msalAuth } from "./plugins/msal";
Vue.use(msalAuth);

import VueSocketIOExt from "vue-socket.io-extended";
import { io } from "socket.io-client";
/* global config */
const socket = io(process.env.VUE_APP_API_URI || config.API_URI, {
  extraHeaders: {
    Authorization: "null",
  },
  autoConnect: false,
});

Vue.use(VueSocketIOExt, socket);

Vue.config.productionTip = false;

new Vue({
  store,
  vuetify,
  dayjs,
  render: (h) => h(App),
}).$mount("#app");
