import axios from "axios";
import store from "@/store/";
/* global config */

const Api = () => {
  let http = axios.create({
    baseURL: process.env.VUE_APP_API_URI || config.API_URI,
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  http.interceptors.request.use(function (config) {
    config.headers["Authorization"] = `Bearer ${store.state.apiToken}`;
    return config;
  });
  return http;
};

export { Api };
