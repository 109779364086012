export const msalConfig = {
  auth: {
    clientId: "62ea7935-bb8f-4a73-b0c6-4ed735f413b8",
    authority:
      "https://login.microsoftonline.com/4ae48b41-0137-4599-8661-fc641fe77bea",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ["User.Read"],
};

export const apiRequest = {
  scopes: ["https://desk-provisioning.pi.arup.com/access_as_user"],
};
