import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "@/authConfig";

export const msalInstance = new PublicClientApplication(msalConfig);

export const msalAuth = {
  install(vue) {
    vue.prototype.$msal = msalInstance;
  },
};

export const getAuthToken = async (scope) => {
  const token = await msalInstance.acquireTokenSilent({
    ...scope,
    account: msalInstance.getAllAccounts()[0],
  });
  return token.accessToken;
};